import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsEditComponent } from './payments-edit.component';
import { CtButtonModule, CtFormModule } from '@ctsolution/ct-framework';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { FlexModule } from '@angular/flex-layout';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
@NgModule({
  imports: [
    CommonModule,
    CtFormModule,
    FormsModule,
    ReactiveFormsModule,
    CtButtonModule,
    MatCardModule,
    FlexModule
  ],
  declarations: [PaymentsEditComponent],
  exports : [PaymentsEditComponent],
  providers: [{ provide: MatDialogRef, useValue: {}}, { provide: MAT_DIALOG_DATA, useValue: {} }, ]

})
export class PaymentsEditModule { }
