<div class="dashboard-container">

  <div fxLayout="row wrap">

    <div
      *ngIf="masterWidgetEnabled"
      fxFlex="100"
      [fxFlex.gt-md]="masterWidgetEnabled ? 35 : 100"
      [fxFlex.gt-lg]="masterWidgetEnabled ? 25 : 100">

      <div *ngIf="notesWidgetEnabled">

        <ct-note-widget [configuration]="noteConfiguration"></ct-note-widget>

      </div>

      <div *ngIf="userWorkingHoursWizardEnabled">

        <app-user-working-hours-wizard></app-user-working-hours-wizard>

      </div>

    </div>

    <div
      fxFlex="100"
      [fxFlex.gt-md]="masterWidgetEnabled ? 65 : 100"
      [fxFlex.gt-lg]="masterWidgetEnabled ? 75 : 100">

      <div fxLayout="row wrap">

        <div fxFlex="100" fxFlex.gt-md="50">

          <div fxLayout="row wrap">

            <div
              fxFlex="100"
              fxFlex.gt-lg="50"
              *ngFor="let counter of counterList">

              <ct-card [configuration]="counter"></ct-card>

            </div>

            <div
              *ngFor="let card of cardList"
              fxFlex="100"
              fxFlex.gt-lg="50">

              <ct-card [configuration]="card"></ct-card>

            </div>

          </div>

        </div>

        <div fxFlex="100" fxFlex.gt-md="50">

          <div *ngFor="let card of masterCardList">

            <ct-card [configuration]="card"></ct-card>

          </div>

          <div *ngFor="let card of masterCardList2">

            <ct-card [configuration]="card"></ct-card>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>
