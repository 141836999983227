<app-lead-edit-form (valueChanged)="onValueChanged($event)" (onSubmit)="submit($event)" [leadOid]="leadOid"
  [masterDetail]="enableMasterDetail"
  [datatableContainerFilterValues]="datatableContainerFilterValues"></app-lead-edit-form>

<ng-container *ngIf="enableMasterDetail">

  <div class="ct-manager-master-detail-container">

    <app-lead-work-order-list [masterConfiguration]="leadModelConfiguration"></app-lead-work-order-list>

  </div>

  <div *ngIf="leadOid" class="ct-manager-master-detail-container">

    <app-lead-documents [configuration]="leadDocumentsConfiguration"></app-lead-documents>

  </div>

  <div *ngIf="schedulerListConfiguration" class="ct-manager-master-detail-container">

    <app-scheduler-list [schedulerListConfiguration]="schedulerListConfiguration"></app-scheduler-list>

  </div>

  <app-lead-place-list *ngIf="showPlace" [masterConfiguration]="leadModelConfiguration"></app-lead-place-list>

  <div class="ct-manager-master-detail-container">

    <app-lead-dynamic-form *ngIf="customerType" [customerType]="customerType"
      [leadModelConfiguration]="leadModelConfiguration"
      [datatableContainerFilterValues]="datatableContainerFilterValues"></app-lead-dynamic-form>

  </div>

</ng-container>

<ng-template #headerActions>

  <app-lang-download-file [configuration]="langDownloadFileConfiguration"
    (onDownload)="downloadPdf($event)"></app-lang-download-file>

</ng-template>