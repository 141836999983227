import {Component, Inject, Optional, ViewChild} from '@angular/core';
import {
  CtBinaryOperator,
  CtDatatableContainerFilterValues,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator,
  CtModelRouteData,
  CtModelService,
  CtModelType
} from "@ctsolution/ct-framework";
import {ActivatedRoute, Router} from "@angular/router";
import {CompanyService} from "../company.service";
import {CompanyRouteData} from "../../../_core/route-data/company.route-data";
import {CustomerType} from "../../../_core/enum/customer-type";
import {CompanyContactConfiguration, CompanyContactFilter} from "../../company-contact/company-contact.configuration";
import {ContactCompany} from "../../company-contact/contact-company";
import {ContactCompanyType} from "../../../_core/enum/contact-company-type";
import {
  CompanyContactRelationService
} from "../../company-contact/company-contact-relation/company-contact-relation.service";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";
import {CompanyEditFormComponent} from './company-edit-form/company-edit-form.component';
import {FormGroup} from '@angular/forms';
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog
} from "@angular/material/legacy-dialog";
import { SchedulerListConfiguration } from '../../scheduler/scheduler-list/scheduler-list.configuration';
import { SchedulerService } from '../../scheduler/scheduler.service';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
})
export class CompanyEditComponent {

  @ViewChild(CompanyEditFormComponent) companyEditFormComponent!: CompanyEditFormComponent;

  companyModelRouteData: CtModelRouteData = CompanyRouteData().setModelType(CtModelType.FORM);
  companyModelConfiguration: CtModelConfiguration<any> = CtModelConfiguration.create();
  companyContactModelConfiguration: CompanyContactConfiguration | null = null;
  datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  leadOid: string | null = null;
  schedulerListConfiguration: SchedulerListConfiguration | null = null;
  leadCompanyConfiguration : CTConfigurationModel = CTConfigurationModel.create();
  contactCompanyConfiguration : CTConfigurationModel = CTConfigurationModel.create();

  calendarVisible: boolean = false;
  isSupplier: boolean = false;

  get enableMasterDetail(): boolean {

    return !!this.companyModelConfiguration?.RouteData?.id;

  }

  get dialogData() : boolean{

    return !!this.dialogConfiguration;

  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modelService: CtModelService<any>,
    private companyService: CompanyService,
    private schedulerService : SchedulerService,
    private companyContactRelationService: CompanyContactRelationService,
    private managerService : CtManagerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogConfiguration: any | undefined,
    private dialogRef: MatDialogRef<CompanyEditComponent>) {

    this.companyModelConfiguration
      ?.setRouteData(this.companyModelRouteData);

    const tpValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!tpValue) return;

    this.isSupplier = this.companyService.getCustomerTypeByRouteValue(tpValue) === CustomerType.Supplier;

    this.setupConfiguration().then()

  }

  async setupConfiguration() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    this.leadOid = this.route.snapshot.queryParamMap.get("LeadOid");

    this.leadCompanyConfiguration.setVisible(await this.managerService.hasEnabled.CRM() ?? false);

    this.calendarVisible = await this.managerService.hasEnabled.calendar()?? false;

    this.managerService.getComponentOverride("company", "leadCompanyConfiguration", this.leadCompanyConfiguration).then((component) => {
      return;
    });

    this.managerService.getComponentOverride("company", "contactCompanyConfiguration", this.contactCompanyConfiguration).then((component) => {
      return;
    });

    if (pId) {

      this.companyModelRouteData.setId(+pId);

      this.modelService
        .getInfos(this.companyModelConfiguration)
        .subscribe({
          next: async (response) => {

            const dataSource = response?.Result?.DataSource;

            if (dataSource) {

              this.companyEditFormComponent.setup(dataSource);

            }

          },
          error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
        })

      await this.setupCompanyContactModelConfiguration();

      const param = this.schedulerService.returnParam(+pId);
      this.schedulerListConfiguration = new SchedulerListConfiguration(
       [ CTModelDatatableFilter.create()
        .setField("[Lead.Customer.Oid] == ?")
        .setValue([+pId])
        .setOperatorType(CtBinaryOperator.OrOperator),
        CTModelDatatableFilter.create()
        .setField("[WorkOrder.Company.Oid] == ?")
        .setValue([+pId])
        .setOperatorType(CtBinaryOperator.OrOperator),
      ],
        param
      ).setOnlyUpdate(true)
      .setAction(() => this.submit(this.companyEditFormComponent!.configuration.form));

      const tpValue: string | null = this.route.snapshot.paramMap.get("type");

      if (tpValue) {
        this.schedulerListConfiguration.setExtraUrlParam(tpValue);
      }


    }

    this.route.data.subscribe(data => data["title"] = this.isSupplier ? "CT_MENU.supplier" : "CT_MENU.customers");
    this.route.queryParams.subscribe(params => {
      const q = params['q'];
      if (q) {

        const filterValues = JSON.parse(q);
        this.datatableContainerFilterValues = CtDatatableContainerFilterValues.create(filterValues);

      }
    });


  }

  submit(formGroup: FormGroup | null) {

    if (!formGroup) return;

    formGroup.markAllAsTouched();

    if (formGroup.valid) {

      const value = formGroup.value;
      value[this.isSupplier ? 'IsSupplier' : 'IsCustomer'] = true;

      this.modelService
        .putInfos(this.companyModelConfiguration, value)
        ?.subscribe({
          next: (response: CtWebapiGenericResponse<number | null>) => {

            if(this.leadOid){

              this.companyService.navigateToLead(this.leadOid);
              return;

            }

            const commands = ['/', 'customer'];

            if (response.Result && !isNaN(response?.Result)) {

              if(this.dialogData){

                this.dialogRef.close(response);
                return

              }

              commands
                .push(
                  this.isSupplier ? 'supplier' : 'private',
                  'edit',
                  response.Result?.toString());

              this.router
                .navigate(commands);

            } else {

              this.setupConfiguration();

            }

          },
          error: (err) => console.error('Errore durante l\'operazione:', err)
        });

    }

  }

  async setupCompanyContactModelConfiguration() {

    // il seguente if abilita la sezione riferimenti solo in determinati casi. mi è stato chiesto di rimuoverlo, ma per sicurezza lascio il codice
    // const CRM = await this.managerService.hasEnabled.CRM() ?? false;
    //
    // if (!CRM) return;

    this.companyContactModelConfiguration = CompanyContactConfiguration
      .create()
      .setOperations([CtModelOperator.Create, CtModelOperator.Update, CtModelOperator.Delete])
      .setCompanyContactFilter(
        CompanyContactFilter
          .create()
          .setField('Companies.Oid')
          .setValue(this.companyModelRouteData.id))
      .setOnCreate(() => this.addContact())
      .setOnEdit(async contactRelation => {

        const returnData = await this.companyContactRelationService.companyContactRelationEdit(contactRelation)

        if (returnData) window.location.reload();

      })
      .setFieldsCustomTemplates([
        {prop: 'Companies', visible: false},
        {prop: 'CompaniesType', visible: false}
      ]);

  }

  private async addContact() {

    const oid = await this.companyContactRelationService.openBinder();

    if (oid) {

      const modelValue: ContactCompany = ContactCompany
        .create()
        .setContactsOid(oid)
        .setCompaniesOid(this.companyModelRouteData?.id ?? -1)
        .setType(ContactCompanyType.Defined);

      this.companyContactRelationService
        .companyContactRelationInsert(modelValue)
        ?.subscribe(() => window.location.reload());

      // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

    }

  }

}
export class CTConfigurationModel {

  visible : boolean = true;
  label? : string;

  constructor(){}

  public static create = (): CTConfigurationModel => new CTConfigurationModel();

  setVisible(value: boolean): CTConfigurationModel {
    this.visible = value;
    return this;
  }

  setLabel(value: string): CTConfigurationModel {

    this.label = value;
    return this;

  }

}