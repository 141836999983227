import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  CtModelRouteData,
  CtModelType,
  CtModelConfiguration,
  CtModelService,
  CtDatatableContainerFilterValues, SnackbarService,
  CTModelDatatableFilter,
  CtBinaryOperator
} from '@ctsolution/ct-framework';
import { LeadRouteData } from '../../../_core/route-data/lead.route-data';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { CustomerTypeEnum, LeadEditFormComponent } from './lead-edit-form/lead-edit-form.component';
import { LeadDocumentsConfiguration } from '../lead-documents/lead-documents.configuration';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { SchedulerListConfiguration } from '../../scheduler/scheduler-list/scheduler-list.configuration';
import { LangDownloadFileConfiguration } from '../../lang-download-file/lang-download-file.configuration';
import { ReportDownloadParameter } from '../../../_core/classes/report-download.parameter';
import { WorkOrderService } from '../../work-order/work-order.service';
import { CompanyType } from '../../../_core/enum/company-type';

@Component({
  selector: 'app-lead-edit',
  templateUrl: './lead-edit.component.html',
  providers: [WorkOrderService]
})
export class LeadEditComponent {

  @ViewChild(LeadEditFormComponent) leadEditFormComponent!: LeadEditFormComponent;
  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  leadModelRouteData: CtModelRouteData = LeadRouteData().setModelType(CtModelType.FORM);
  leadModelConfiguration: CtModelConfiguration<any> = CtModelConfiguration.create();
  datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  leadDocumentsConfiguration: LeadDocumentsConfiguration = LeadDocumentsConfiguration.create();
  leadOid: number | null = null;
  customerType: CustomerTypeEnum | null = null;
  schedulerListConfiguration: SchedulerListConfiguration | null = null;

  langDownloadFileConfiguration: LangDownloadFileConfiguration = LangDownloadFileConfiguration
    .create()
    .setColor("accent")
    .setIcon("picture_as_pdf")
    .setButtonType('mat-mini-fab')
    .setLanguage([
      {
        language: "Italiano",
        code: "it",
        type: "IT",
        icon: "it"
      }]);

  get enableMasterDetail(): boolean {

    return !!this.leadModelConfiguration?.RouteData?.id;

  }

  showPlace = true;

  onValueChanged(shouldShow: boolean) {
    this.showPlace = shouldShow;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _snackbar: SnackbarService,
    private workOrderService: WorkOrderService,
    private modelService: CtModelService<any>,
    private schedulerService: SchedulerService) {

    this.leadModelConfiguration.setRouteData(this.leadModelRouteData);
    this.setupConfiguration();


  }

  ngAfterViewInit() {

    this.route
    .data
    .subscribe(data => {

      data['headerActions'] = this.headerActions;

    });

  }

  setupConfiguration() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) {

      const param = this.schedulerService.returnParam(+pId);
      this.schedulerListConfiguration = new SchedulerListConfiguration([CTModelDatatableFilter.create().setField('Lead.Oid').setValue(+pId).setOperatorType(CtBinaryOperator.Equal)], param).setAction(() => this.submit(this.leadEditFormComponent!.configuration.form));


      this.leadOid = +pId;

      this.leadModelRouteData.setId(+pId)

      this.leadDocumentsConfiguration?.setLeadOid(this.leadOid);

      let title = "Lead";
      this.route.data.subscribe(data => data["title"] = title);

      this.modelService
        .getInfos(this.leadModelConfiguration)
        .subscribe({
          next: async (response) => {

            const dataSource = response?.Result?.DataSource;

            if (dataSource) {

              this.leadEditFormComponent.setup(dataSource);
              this.customerType = dataSource.Customer?.Type?.Type ?? dataSource.CustomerType.Type;
              //TODO: soluzione momentanea finche non verrà fatto lo sviluppo per filtrare per LeadType
              title = (this.customerType == CustomerTypeEnum.BUSINESS) ? "LeadBusinessButton" : "LeadPrivateButton";
              this.route.data.subscribe(data => data["title"] = title);

            }

          },
          error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
        })

    }
    this.route.queryParams.subscribe(params => {
      const q = params['q'];
      if (q) {

        const filterValues = JSON.parse(q);
        if(filterValues) {
          this.datatableContainerFilterValues = CtDatatableContainerFilterValues.create(filterValues);
        }

      }
    });




  }

  downloadPdf(lang = 'it') {

    if (!this.leadOid) return;

    const parameter: ReportDownloadParameter = ReportDownloadParameter
      .create()
      .setLeadOid(this.leadOid)
      .setTemplateLanguage(lang)
      .setDirectDownload(false)
      .setOpenInBrowser(true)
      .setAction('LeadPdf')
      .setLabelDownload(`Lead${this.leadOid}`);

    this.workOrderService.downloadWorkOrderPDF(parameter)

  }

  submit(formGroup: FormGroup | null) {

    if (!formGroup) return;

    formGroup.markAllAsTouched();


    if (formGroup.valid) {

      const value: ILead = formGroup.value as ILead;

      if (value.LeadType.Code) {

        value.LeadType = value.LeadType.Code;

      }

      const Tags: { Oid: number }[] = (value?.Tags ?? []).map(tag => ({ Oid: tag.value }));

      const formValue: any = {
        Customer: value.Customer ? { Oid: value.Customer.value } : null,
        CustomerType: value.CustomerType ? { Oid: value.CustomerType } : null,
        DocumentDate: value.DocumentDate,
        Email: value.Customer ? null : value.Email,
        LeadInterest: value.LeadInterest ? { Oid: value.LeadInterest } : null,
        LeadSource: value.LeadSource ? { Oid: value.LeadSource } : null,
        LeadStatus: value.LeadStatus ? { Oid: value.LeadStatus } : null,
        LeadType: value.LeadType ? { Oid: value.LeadType } : null,
        Name: value.Customer ? null : value.Name,
        Phone: value.Customer ? null : value.Phone,
        Surname: value.Customer ? null : value.Surname,
        CompanyPlace: value.CompanyPlace ? { Oid: value.CompanyPlace } : null,
        Code: value.Code,
        Note: value.Note,
        VatNumber: (<any>value).VATNUMBER ?? null,
        SdiCode: (<any>value).SDICODE ?? null,
        Tags

      }; //TODO guardare dts per

      this.modelService
        .putInfos(this.leadModelConfiguration, formValue)
        ?.subscribe({
          next: (response: CtWebapiGenericResponse<number | null>) => {

            this._snackbar.onSuccess();

            const commands = ['/', 'lead'];

            if (response.Result && !isNaN(response?.Result)) {

              commands
                .push(
                  'edit',
                  response.Result?.toString());

              this.router.navigate(commands, { queryParamsHandling: 'preserve' });

            } else {

              this.setupConfiguration();

            }

          },
          error: (err) => console.error('Errore durante l\'operazione:', err)
        });
    }

  }

}

export interface ILead {

  Customer: any | null
  CustomerType: number | null
  DocumentDate: any
  Email: string
  LeadInterest: number | null
  LeadSource: number | null
  LeadStatus: number
  LeadType: any
  Name: string
  Phone: string
  Surname: string
  CompanyPlace: number | null
  Code: string | null
  Tags: any[] | null
  Note: string | null

}
