import { Component, OnInit } from '@angular/core';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtSchedulerConfiguration, CtSchedulerEvent } from '@ctsolution/ct-framework';
import { SchedulerRouteData } from '../../_core/route-data/schedule.route-data';
import { SchedulerService } from './scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTypeEnum, SchedulerEventPath } from './scheduler-event-button/scheduler-event-button.component';
import { CalendarEvent } from 'angular-calendar';
import { CTMGeneralService } from '../../_core/lib/general.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {


  schedulerConfiguration: CtSchedulerConfiguration = CtSchedulerConfiguration
    .create()
    .setLocale('it')
    .setOnCreate(async (value: any) => this.onCreate(value))
    .setOnEdit((value: CalendarEvent) => this.onEdit(value));

  configuration: CtModelConfiguration<any> = CtModelConfiguration.create()
    .setRouteData(SchedulerRouteData())
    .setCTSchedulerConfiguration(this.schedulerConfiguration)

  constructor(private schedulerService: SchedulerService, private route: ActivatedRoute, private router: Router, private generalService: CTMGeneralService) { }

  ngOnInit() {

    let pid: string | null = null;
    this.route.queryParams.subscribe(params => {
      pid = params['IdEvent'] || null;
    });

    if (pid) {
      this.generalService.getList({
        controller: 'scheduler',
        filters: [
          CTModelDatatableFilter
            .create()
            .setField('Oid')
            .setValue(pid)
            .setOperatorType(CtBinaryOperator.Equal)
        ]
      }).subscribe(response => {

        this.onEditFly(response.Result.DataSource[0]);

      });
    }

  }

  async onCreate(value: any) {


    const type = this.route.snapshot.queryParamMap.get('TypeExtra');
    const oid = this.route.snapshot.queryParamMap.get('OidExtra');
    let param: SchedulerEventPath | null = null;
    if (type && oid)
      param = SchedulerEventPath.create(+oid, +type);

    const ret = await this.schedulerService.createEventFromPath(this.configuration, param, null, value);

    if (ret && param && type) {
      this.router.navigate([this.schedulerService.getPathToReturn(+type), 'edit', oid]) //TODO: Rivedere quando ci saranno piu tipi
      return
    }

    if (ret)
      window.location.reload();
  }

  async onEdit(value: CalendarEvent) {

    const ret = await this.schedulerService.createEventFromPath(this.configuration, null, (<any>value).data);
    if (ret)
      window.location.reload();
  }

  async onEditFly(value: CtSchedulerEvent) {

    const type = this.route.snapshot.queryParamMap.get('TypeExtra');
    const oid = this.route.snapshot.queryParamMap.get('OidExtra');
    const extraUrl = this.route.snapshot.queryParamMap.get('ExtraUrl');

    let param: SchedulerEventPath | null = null;
    if (type && oid)
      param = SchedulerEventPath.create(+oid, +type);
    const ret = await this.schedulerService.createEventFromPath(this.configuration, null, value);
    if (ret && param && type) {
      this.router.navigate([this.schedulerService.getPathToReturn(+type,extraUrl) ,'edit', oid]) //TODO: Rivedere quando ci saranno piu tipi
      return
    }

    if (ret)
      window.location.reload();
  }

}
