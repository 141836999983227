import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelRouteData, CtModelService,
  CtModelType,
  MAT_RAISED_PRIMARY,
} from "@ctsolution/ct-framework";
import {WorkOrderRouteData} from "../../../../_core/route-data/work-order.route-data";
import {CTWorkOrder} from "../../../../_core/classes/ct-work-order";
import {GeneralDataComponent} from "./general-data/general-data.component";
import {CTMWorkOrderViewType} from "../../../../_core/enum/work-order-type";
import {WorkOrderService} from "../../work-order.service";
import {GeneralDataConfiguration} from "./general-data/general-data.configuration";
import {MovementManagerComponent} from "./movement-manager/movement-manager.component";
import {CtManagerService} from "../../../../_core/lib/ct-manager.service";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CTWorkOrderRow} from "../../../../_core/classes/ct-work-order.row";
import {CtWorkOrderInformationContent} from "../../../../_core/classes/ct-work-order.information-content";
import {CTWorkOrderRowUnionType} from "../../../../_core/classes/ct-work-order.row-union";
import {MovementManagerConfiguration} from "./movement-manager/movement-manager.configuration";
import {WorkOrderHandlingsConfiguration} from "./work-order-handlings/work-order-handlings.configuration";
import {WorkOrderDocumentsConfiguration} from './work-order-documents/work-order-documents.configuration';
import {TranslateService} from "@ngx-translate/core";
import { LangDownloadFileConfiguration } from '../../../lang-download-file/lang-download-file.configuration';
import { ReportDownloadParameter } from 'projects/ct-manager/src/app/_core/classes/report-download.parameter';
import { PackagingService } from '../../work-order-packaging/work-order-packaging/work-order-packaging.service';
import { WorkOrderLocationConfiguration } from './work-order-location/work-order-location.configuration';
import { SchedulerListConfiguration } from '../../../scheduler/scheduler-list/scheduler-list.configuration';
import { SchedulerService } from '../../../scheduler/scheduler.service';
import { WorkOrderPackagingConfiguration } from '../../work-order-packaging/work-order-packaging/work-order-packaging.configuration';


@Component({
  selector: 'app-work-order-default-edit',
  templateUrl: `./work-order-default-edit.component.html`,
  styleUrls: [`./work-order-default-edit.component.scss`]
})
export class WorkOrderDefaultEditComponent {

  pId: string | null = null;
  configuration: CtModelConfiguration<WorkOrderDefaultEditComponent> | null = null;
  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;


  viewModel: WorkOrderDefaultEditViewModel = {
    workOrder: null,
    workOrderViewType: CTMWorkOrderViewType.Standard,
    workOrderRowHandlingEnabled: false,
    workOrderExtraDataEnabled: false,
    workOrderCalendarEnabled: false,
    selectedIndex: 0,
    masterDetail: false,
    offerEnabled: false,

  }

  langDownloadFileConfiguration: LangDownloadFileConfiguration = LangDownloadFileConfiguration
  .create()
  .setColor("accent")
  .setIcon("picture_as_pdf")
  .setButtonType('mat-mini-fab')
  .setLanguage([
    {
      language: "Italiano",
      code: "it",
      type: "IT",
      icon: "it"
    }]);

  generalDataConfiguration: GeneralDataConfiguration | null = null;
  movementManagerConfiguration: MovementManagerConfiguration | null = null;
  workOrderHandlingsConfiguration: WorkOrderHandlingsConfiguration | null = null;
  workOrderDocumentsConfiguration: WorkOrderDocumentsConfiguration | null = null;
  workOrderLocationConfiguration: WorkOrderLocationConfiguration | null = null;
  schedulerListConfiguration : SchedulerListConfiguration | null = null;



  creaPackagingButtonConfiguration: CtButtonConfiguration | null = null;

  @ViewChild('generalDataComponent') generalDataComponent: GeneralDataComponent | null = null;
  @ViewChild('movementManagerComponent') movementManagerComponent: MovementManagerComponent | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private workOrderService: WorkOrderService,
    private managerService: CtManagerService,
    private CTModelService: CtModelService<any>,
    private packagingService: PackagingService,
    private schedulerService : SchedulerService
    ) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngOnInit() {

    this.setup();

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp['rows']) {

          setTimeout(() => this.viewModel.selectedIndex = 1, 200)

        }

      })

      this.route
      .data
      .subscribe(data => {

        data['headerActions'] = this.headerActions;

      });

  }

  get isQuotation() {

    return this.viewModel.workOrderViewType === CTMWorkOrderViewType.Quotation;

  }

  async setup() {

    this.viewModel.workOrderViewType = await this.workOrderService.getRouteWorkOrderType(this.route);
    // this.viewModel.offerEnabled = await this.managerService.hasEnabled.CRM();
    //TODO: come controllo che le offerte sia abilitate?
    this.viewModel.workOrderExtraDataEnabled = await this.managerService.hasEnabled.monitoring();
    this.viewModel.workOrderCalendarEnabled = await this.managerService.hasEnabled.calendar();


    const routeData: CtModelRouteData = WorkOrderRouteData()
      .setModelType(CtModelType.FORM);

    if (this.pId) {

      routeData.setId(+this.pId);
      this.viewModel.workOrderRowHandlingEnabled = await this.managerService.hasEnabled.warehouse();
      this.viewModel.masterDetail = true;

      if(this.viewModel.workOrderCalendarEnabled){

        const param = this.schedulerService.returnParam(+this.pId);
        this.schedulerListConfiguration = new SchedulerListConfiguration([CTModelDatatableFilter.create().setField('Workorder.Oid').setValue(+this.pId).setOperatorType(CtBinaryOperator.Equal)],param).setAction(() => this.submit(null));

      }

    }

    this.configuration = CtModelConfiguration
      .create<WorkOrderDefaultEditComponent>()
      .setRouteData(routeData);

    if (this.pId) {

      this.CTModelService
        .getInfos(this.configuration)
        .subscribe({
          next: async (response) => {

            if (response && response?.Result?.DataSource) {

              this.viewModel.workOrder = CTWorkOrder.create<CtWorkOrderInformationContent>(response?.Result?.DataSource);

              if (this.viewModel.workOrder.Oid) {

                const [rows, rowsItem, rowsOffer, rowsContact] = await Promise.all([
                  this.workOrderService.getRows(this.viewModel.workOrder.Oid).catch(error => []),
                  [], //this.workOrderService.getRowsItem(this.viewModel.workOrder.Oid).catch(error => []),
                  [], //this.workOrderService.getRowsOffer(this.viewModel.workOrder.Oid).catch(error => []),
                  [] //this.workOrderService.getRowsContact(this.viewModel.workOrder.Oid).catch(error => [])
                ]);

                this.route
                  .data.subscribe(data => {

                  const {title, code, referenceName} = {
                    title: this.translate.instant(this.isQuotation ? "QUOTATION" : "SingleWorkOrder"),
                    code: this.viewModel.workOrder?.Code ?? "",
                    referenceName: response?.Result?.DataSource.Company.ReferenceName ?? ""
                  };

                  //data['title'] = [title, code, referenceName].filter(Boolean).join(' - ');
                  data['title'] = `${title} ${code} - ${referenceName}`

                  this.cdr.detectChanges();

                  this.cdr.detectChanges();

                })

                this.viewModel.workOrder.Rows = new Array<CTWorkOrderRowUnionType>();

                [rows, rowsItem, rowsOffer, rowsContact]
                  .forEach(rowArray => {

                    if (rowArray?.length) rowArray.forEach(r => this.viewModel.workOrder?.Rows.push(CTWorkOrderRow.create(r)));

                  });

                if (this.generalDataComponent) this.generalDataComponent.setup(this.viewModel.workOrder);

                if (this.movementManagerComponent) this.movementManagerComponent.setup(this.viewModel.workOrder);

              }
              this.creaPackagingButtonConfiguration = CtButtonConfiguration
              .create()
              .setLabel("CreatePackaging")
              .setAction(() => this.createPackaging())
              .setMatherialOptions(MAT_RAISED_PRIMARY)
              .setVisible(false);
             //Creazione configurazione button Crea Packaging nel click trasportare gli articoli presenti in commessa

             this.managerService.getComponentOverride("workOrder", "createPackaging", this.creaPackagingButtonConfiguration).then((component) => {
              return;
            });


            }

          },
          error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
        })

    }

    this.generalDataConfiguration = GeneralDataConfiguration
      .create(this.viewModel.workOrderViewType)
      .setWorkOrderOid(this.pId ? +this.pId : null)
      .enableImport(this.viewModel.workOrderRowHandlingEnabled)
      .enableExtraDataJson(this.viewModel.workOrderExtraDataEnabled);

    this.movementManagerConfiguration = MovementManagerConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)
      .setIsQuotation(this.isQuotation)
      .setEnableWerehouse(this.viewModel.workOrderRowHandlingEnabled);

      this.managerService.getComponentOverride("workOrder", "movementManagerConfiguration", this.movementManagerConfiguration).then((component) => {
        return;
      });

    this.workOrderHandlingsConfiguration = WorkOrderHandlingsConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)

    this.workOrderDocumentsConfiguration = WorkOrderDocumentsConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)

    this.workOrderLocationConfiguration = WorkOrderLocationConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)

    this.route
      .data
      .subscribe(data => {

        data['title'] = (this.isQuotation) ? "CT_MENU.quotations" : "CT_MENU.workorder";
        data['urls'] = [{title: (this.isQuotation) ? 'quotation-form' : 'workorder-form'}];

      })

    this.cdr.detectChanges();

  }

  submit(acceptQuotationDate: Date | null) {

    if (!this.configuration || this.generalDataComponent?.formConfiguration?.form?.invalid) return;

    const generalDataValue = this.generalDataComponent?.formConfiguration?.form?.value;

    if (!generalDataValue) {

      console.error('Dati non validi');
      return;

    }

    const workOrder = CTWorkOrder
      .create<CtWorkOrderInformationContent>()
      .setName(generalDataValue.Name)
      .setCompanyOid(generalDataValue.Customer.value)
      .setCode(generalDataValue.Code)
      .setStateOid(generalDataValue.WorkOrderState)
      .setStartAt(generalDataValue.SupplyStartDate)
      .setExpiredAt(generalDataValue.SupplyExpirationDate)
      .setAddress(generalDataValue.Address?.value ? generalDataValue.Address.label : generalDataValue.Address)
      .setDescription(generalDataValue.Description)
      .setExtraDataJSON(generalDataValue.ExtraDataJSON ?? null);

    if (this.isQuotation) {

      if (acceptQuotationDate) {

        workOrder
          .setQuotationAcceptAt(acceptQuotationDate);

      }

      workOrder
        .setQuotation(true);

    }

    this.CTModelService
      .putInfos(this.configuration, workOrder)
      ?.subscribe({
        next: (response: CtWebapiGenericResponse<number | null>) => {

          const commands = ['/', 'work-order'];

          if (response.Result && !isNaN(response?.Result)) {

            if (this.isQuotation) commands.push('quotations')

            commands
              .push('edit', response.Result?.toString());

            this.router
              .navigate(commands);

          } else if (this.pId && this.isQuotation && !!acceptQuotationDate) {

            commands
              .push('edit', this.pId);

            this.router
              .navigate(commands);

          } else {

            this.setup();

          }

        },
        error: (err) => console.error('Errore durante l\'operazione:', err)
      });
  }

  downloadPdf(lang = 'it') {

    if (!this.viewModel?.workOrder?.Oid) return;

    const parameter: ReportDownloadParameter = ReportDownloadParameter
      .create()
      .setWorkOrderOid(this.viewModel.workOrder.Oid)
      .setTemplateLanguage(lang)
      .setDirectDownload(false)
      .setOpenInBrowser(true)
      .setAction('WorkOrderPdf')
      .setLabelDownload(`WorkOrder${this.viewModel.workOrder.Oid}`);

      this.workOrderService.downloadWorkOrderPDF(parameter)


  }

  createPackaging (){

    if(!this.pId) return;

    const param = WorkOrderPackagingConfiguration.create();
    param.setWorkOrderOid(+this.pId)
    this.packagingService.openDialogPackaging(param);
  }
}

export interface WorkOrderDefaultEditViewModel {
  workOrder: CTWorkOrder<CtWorkOrderInformationContent> | null;
  workOrderViewType: CTMWorkOrderViewType | null;
  workOrderRowHandlingEnabled: boolean;
  workOrderExtraDataEnabled: boolean;
  workOrderCalendarEnabled: boolean;
  selectedIndex: number;
  masterDetail: boolean;
  offerEnabled: boolean;
}
