export class OfferFeeManagerConfiguration {

  Offer: {
    Oid: number | null
  } | null = null;

  private constructor() {
  }

  public static create = () => new OfferFeeManagerConfiguration();

  setOfferOid(value: number | null) {

    this.Offer = {
      Oid: value
    }

    return this;

  }

}
