import { Component} from '@angular/core';
import { CtButtonConfiguration, MAT_RAISED_ACCENT, MAT_RAISED_PRIMARY } from '@ctsolution/ct-framework';
import { createFromLead } from '../../../_core/route-data/work-order.route-data';
import { CtWebapiService } from '@ctsolution/ct-webapi';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-lead-create-workorder',
  templateUrl: './lead-create-workorder.component.html',
  styleUrls: ['./lead-create-workorder.component.scss']
})
export class LeadCreateWorkorderComponent {

  submitButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("Crea preventivo")
  .setAction(() => this.submit())
  .setMatherialOptions(MAT_RAISED_PRIMARY);


  constructor(private webApiService : CtWebapiService, private route : ActivatedRoute, private router : Router) { }

  submit(){

    const pId = this.route.snapshot.paramMap.get("id");
    if(pId == null) return;

    const queryParams = {
      id : pId
    }

    this.webApiService.post(createFromLead().setQueryParams(queryParams)).subscribe(response => {


      if(response == null) return;
      this.router.navigate(['/' , 'work-order' ,'quotations', 'edit' , response.Result]);

    });

  }

}
