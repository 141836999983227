<app-form-module-data-form *ngIf="dataSource" (onSubmit)="submit($event)" [dataSource]="dataSource"></app-form-module-data-form>

<div>

  <ng-container *ngIf="formConfigurations.length > 0">

    <ng-container *ngFor="let configuration of formConfigurations">

      <ct-dynamic-form-generator #formGenerator [configuration]="configuration"></ct-dynamic-form-generator>

    </ng-container>

  </ng-container>

</div>

