import { Injectable } from "@angular/core";
import { EventTypeEnum, SchedulerEventPath } from "./scheduler-event-button/scheduler-event-button.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CtModelConfiguration, CtSchedulerEvent, CtSchedulerEventFormComponent, CtSchedulerEventFormConfiguration } from "@ctsolution/ct-framework";
import { ActivatedRoute, Router } from "@angular/router";
import { CTMGeneralService } from "../../_core/lib/general.service";

@Injectable({
  providedIn: 'root'
})

export class SchedulerService {

  constructor(
    private dialog: MatLegacyDialog,
    private router: Router,
    private route: ActivatedRoute,
    private general: CTMGeneralService
  ) {
  }

  async createEventFromPath(modelConfiguration: CtModelConfiguration<any>, param?: SchedulerEventPath | null, value?: CtSchedulerEvent | null, event?: any) {

    let eventValue: CtSchedulerEvent | null = null;

    const configuration = CtSchedulerEventFormConfiguration
      .create()
      .setModelConfiguration(modelConfiguration)

    if (value) {

      eventValue = value;

      if (typeof eventValue.Start === 'string') {
        eventValue.Start = new Date(eventValue.Start);
      }

      if (typeof eventValue.End === 'string') {
        eventValue.End = new Date(eventValue.End);
      }

      configuration
        .setEventValue(eventValue)

      if (value.Origin && value.ExternalOid) {
        const subtitle = await this.getSubtitle(+value.Origin, value.ExternalOid);
        if (subtitle) (<any>eventValue).SubTitle = subtitle; // dam: commento per jack, ho aggiunto l'any come cast perché altrimenti non compilava, forse qualche problema di publish del pacchetto


        configuration.setnavigateSubmit(() => {
          this.router.navigate([this.getPathToReturn(+value.Origin), 'edit', value.ExternalOid])
          this.dialog.closeAll();
        })

      }
    }

    if (param?.Oid && param?.Type) {
      eventValue = <CtSchedulerEvent>{ Start: event.date }
      eventValue.Origin = +param?.Type;
      eventValue.ExternalOid = param?.Oid;
      configuration.setEventValue(eventValue);
    }

    if (eventValue == null && event) {
      configuration.setEventValue(<CtSchedulerEvent>{ Start: event.date });

    }


    return this.dialog
      .open(CtSchedulerEventFormComponent, { disableClose: true, maxWidth: 500, data: configuration })
      .afterClosed().toPromise();

  }

  getPathToReturn(origin: number, extraUrl: string | null = null) {

    switch (origin) {
      case EventTypeEnum.LEAD:
        return 'lead';
      case EventTypeEnum.WORKORDER:
        return 'work-order';
      case EventTypeEnum.CUSTOMER:
        if(extraUrl) return `customer/${extraUrl}`
        return 'customer';
      default:
        return '';
    }

  }

  navigateToPath(path: SchedulerEventPath) {

    const queryParams = {
      TypeExtra: path.Type,
      OidExtra: path.Oid
    };

    // Naviga verso il nuovo path mantenendo eventuali altri queryParams esistenti
    this.router.navigate(['scheduler'], { queryParamsHandling: 'merge', queryParams }
    );


  }


  returnParam(id: number | null = null) {

    const pId = this.route.snapshot.paramMap.get("id");
    let param: SchedulerEventPath | null = null;
    const currentUrl = this.router.url;
    let type: EventTypeEnum | null = null;

    if (currentUrl.includes('lead')) {
      type = EventTypeEnum.LEAD;
    } else if (currentUrl.includes('work-order')) {
      type = EventTypeEnum.WORKORDER;
    } else if (currentUrl.includes('customer')) {
      type = EventTypeEnum.CUSTOMER;
    }


    if (id)
      param = SchedulerEventPath.create(id, type);
    else
      if (pId)
        param = SchedulerEventPath.create(+pId, type);

    return param

  }


  getSubtitle(origin: EventTypeEnum, oid: number) {

    switch (origin) {

      case EventTypeEnum.LEAD:
        return this.getLeadSubtitle(oid);

      case EventTypeEnum.WORKORDER:
        return this.getWorkOrderSubtitle(oid);

      default:
        return "";


    }


  }

  private getLeadSubtitle(oid: number): Promise<string> {

    return new Promise<any | null>(resolve => {
      this.general.getInfos(
        {
          controller: 'Lead',
          oid: oid
        }
      ).subscribe(result => {

        if (result.Result?.DataSource) {

          const res = `${result.Result.DataSource.Name ?? ""} ${result.Result.DataSource.Surname ?? ""}`;
          resolve(res)

        }

      })

    })

  }


  private getWorkOrderSubtitle(oid: number) {

    return new Promise<any | null>(resolve => {
      this.general.getInfos(
        {
          controller: 'WorkOrder',
          oid: oid
        }
      ).subscribe(result => {

        if (result.Result?.DataSource) {

          const res = `${result.Result.DataSource.Name ?? ""} ${result.Result.DataSource.Code ?? ""}`;
          resolve(res)

        }

      })

    })

  }

  navigateSubmit() {



  }

}
