import { CTBase } from "@ctsolution/ct-base";

export class WorkOrderPayment extends CTBase<WorkOrderPayment>{

     Amount : number | null = null;
     Description : string | null = null;
     Notes : string | null = null;
     UserInfo :  { Oid: number } | null = null;
     PaymentDate : Date | null = null;
     Origin : OriginPaymentEnum | null = null;
     WorkOrder :  { Oid: number | null } | null = null;
     Company : { Oid: number | null } | null = null;
     Status : { Oid: number | null } | null = null
     Contact : { Oid: number | null } | null = null


    private constructor();
    private constructor(model?: WorkOrderPayment);
    private constructor(model?: WorkOrderPayment) {

      super();

      if (model) this.getClass(model);

    }

    public static create = (model?: WorkOrderPayment): WorkOrderPayment => new WorkOrderPayment(model);

    setWorkOrderOid(value: number | null): WorkOrderPayment {

        this.WorkOrder = {Oid: value};
        return this;

    }

    setCompanyOid(value: number | null): WorkOrderPayment {

        this.Company = {Oid: value};
        return this;

    }

    setStatusOid(value: number | null): WorkOrderPayment {

        this.Status = {Oid: value};
        return this;

    }

    setContactOid(value: number | null): WorkOrderPayment {

        this.Contact = {Oid: value};
        return this;

    }

    setOrigin(value : OriginPaymentEnum): WorkOrderPayment {

        this.Origin = value;
        return this;

    }

    setUserInfoOid(value: number): WorkOrderPayment {

        this.UserInfo = {Oid: value};
        return this;

    }
}

export enum OriginPaymentEnum{

    AUTOMATICO = 1,
    MANUALE = 2

}
