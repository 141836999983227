import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  CTGeneralService,
  CtButtonConfiguration,
  CtControlTypes,
  CtFormConfiguration,
  CtModelDatatableOperators,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN, CtControlService, CtModelService, CtModelConfiguration, CtSelectControlValue, CtSelectControlOptions,
  CtControlValidator,
  CtDatatableContainerFilterValues
} from '@ctsolution/ct-framework';
import {CTMGeneralService} from 'projects/ct-manager/src/app/_core/lib/general.service';
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CompanyTypeRouteData} from "../../../../_core/route-data/company-type.route-data";
import {CompanyType} from "../../../../_core/enum/company-type";

@Component({
  selector: 'app-company-edit-form',
  templateUrl: './company-edit-form.component.html',
})
export class CompanyEditFormComponent implements AfterViewInit {

  @Output() onSubmit: EventEmitter<FormGroup | null> = new EventEmitter<FormGroup | null>();
  @Input() isSupplier: boolean = false;
  @Input() isDialog : boolean = false;
  @Input() datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;

  configuration: CtFormConfiguration;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onSubmit.emit(this.configuration.form))
    .setMatherialOptions(MAT_RAISED_ACCENT);

  // private referenceNameControl = this.generalService.getTextControl({
  //   name: 'ReferenceName',
  // });

  private nameControl = this.generalService.getTextControl({
    name: 'Name',
  }).setLabel("Company.Name");

  private surnameControl = this.generalService.getTextControl({
    name: 'Surname',
  }).setLabel("Company.Surname");

  private fiscalCodeControl = this.generalService.getTextControl({
    name: 'FiscalCode'
  })

  private vatControl = this.generalService.getTextControl({
    name: 'VAT'
  });

  private referenceName = this.generalService.getTextControl({
    name: 'ReferenceName'
  }).setLabel("Company.ReferenceName");

  private isSelfControl = this.controlService.getDefaultConfiguration({
    name : 'IsSelf',
    type : CtControlTypes.ENUMERABLE
  }).setHidden(true).setValue(false);

  private typeLookup = this.controlService
    .getDefaultConfiguration({
      name: 'Type',
      type: CtControlTypes.ENUMERABLE,
      flexSpacePercentage: 50,
      required: true
    })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setObjectComparisonFunction((o1, o2) => o1.Oid === o2.Oid)

    })

  private controls = [
    this.typeLookup,
    this.nameControl,
    this.surnameControl,
    this.fiscalCodeControl,
    this.vatControl,
    this.referenceName,
    this.isSelfControl
  ];

  constructor(
    private CTGeneralService: CTGeneralService,
    private controlService: CtControlService,
    private generalService: CTMGeneralService,
    private modelService: CtModelService<any>,
    private formBuilder: FormBuilder) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

    this.setupCompanyTypes();

  }

  ngAfterViewInit() {

    this.typeLookup
      .control
      ?.valueChanges
      .subscribe(value => {
        switch (value.Type) {

          case CompanyType.Private:

            this.vatControl.setHidden(true);
            this.referenceName.setHidden(true).setValidators([]);
            this.surnameControl.setValidators([CtControlValidator.create({name: "required"} as CtControlValidator)]);

            break;

          case CompanyType.Business:

            this.vatControl.setHidden(false);
            this.referenceName.setHidden(false).setValidators([CtControlValidator.create().setName('required')]);
            this.surnameControl.setValidators([]);

            break;

        }

      })

  }

  setup<T>(value: T | null) {

    if (!value) return;

    this.configuration.form?.patchValue(value);

  }

  private setupCompanyTypes() {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(CompanyTypeRouteData())
    const body: CtModelDatatableOperators = CtModelDatatableOperators.create();

    this.modelService
      .getList(configuration, body)
      .subscribe((response: CtWebapiGenericResponse<any>) => {

        const datasource = response?.Result?.DataSource ?? [];

        const valueOptionsTypeLookup: CtSelectControlValue[] = datasource
          .map((element: any) =>
            CtSelectControlValue
              .create()
              .setLabel(element.Name).setValue(element))

        this.typeLookup.setValueOptions(valueOptionsTypeLookup);

        if (!this.typeLookup.control?.value) {

          const defaultValue = datasource.find((elm: any) => elm.Type === (this.isSupplier ? CompanyType.Business : CompanyType.Private))

          if (defaultValue) {

            this.typeLookup
              .setValue(defaultValue);

          }

        }

        const typeValue = this.getValueFilterType();

        if(typeValue)
        {

          const defaultValue = datasource.find((elm: any) => elm.Type ===  +typeValue)

          if (defaultValue) {

            this.typeLookup
              .setValue(defaultValue);

            this.typeLookup.setDisabled(true); 

            //Per Waddi serve non dare la possibilità di creare una company di tipo privato se viene filtrata dalla query
          }


        }

      })

  }

  getValueFilterType(): string | null {

    let value: string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field === 'Type.Type')?.Value

    }

    return value

  }


}
