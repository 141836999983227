import {Component, Input, OnInit} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from '@ctsolution/ct-framework';
import {CTMGeneralService} from 'projects/ct-manager/src/app/_core/lib/general.service';
import {Router} from '@angular/router';
import {LeadRedirectButtonConfiguration} from "./lead-redirect-button.configuration";
import {WorkOrderRouteData} from "../../../../../../_core/route-data/work-order.route-data";
import { CompanyType } from 'projects/ct-manager/src/app/_core/enum/company-type';

@Component({
  selector: 'app-lead-redirect-button',
  templateUrl: './lead-redirect-button.component.html'
})
export class LeadRedirectButtonComponent implements OnInit {

  @Input() configuration: LeadRedirectButtonConfiguration | null = null;

  lead: any | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Lead")
    .setAction(() => this.router.navigate(['/', 'lead', 'edit', this.lead?.Oid]))
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setIcon('fiber_new');

  constructor(private generalService: CTMGeneralService, private router: Router) {
  }

  ngOnInit() {

    this.setup();

  }

  setup() {

    this.generalService.getInfos({
      controller: WorkOrderRouteData().controller!,
      oid: this.configuration?.WorkOrder?.Oid
    }).subscribe(result => {
      
      if (result) this.lead = result.Result.DataSource.Lead;

      if(this.lead?.Customer?.Type.Type == CompanyType.Business || this.lead?.CustomerType?.Type == CompanyType.Business) {

        this.submitButton.setLabel("LeadBusinessButton");

      }else if (this.lead?.Customer?.Type.Type == CompanyType.Private || this.lead?.CustomerType?.Type == CompanyType.Private) {
        
        this.submitButton.setLabel("LeadPrivateButton");

      }

      if (this.lead?.Code) {

        this.submitButton
          .setLabel(`${this.submitButton.label} (${this.lead?.Code})`)

      }

    })

  }

}
