import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CtModelConfiguration, CtModelRouteData, CtModelType } from '@ctsolution/ct-framework';
import { CtManagerService } from '../../../_core/lib/ct-manager.service';
import { OfferRouteData } from '../../../_core/route-data/offer.route-data';
import { OfferFeeManagerConfiguration } from './offer-fee/offer-fee.configuration';

@Component({
  selector: 'app-offer-edit',
  templateUrl: './offer-edit.component.html',
})
export class OfferEditComponent implements AfterViewInit {

  configuration: CtModelConfiguration<OfferEditComponent> | null = null;
  offerFeeManagerConfiguration: OfferFeeManagerConfiguration = OfferFeeManagerConfiguration.create();

  get enableMasterDetail(): boolean {

    return !!this.configuration?.RouteData?.id;

  }

  constructor(private route: ActivatedRoute, private managerService: CtManagerService) { }

  ngAfterViewInit() {

    this.setup();

  }


  async setup() {

    const routeData: CtModelRouteData = OfferRouteData()
      .setModelType(CtModelType.FORM);

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) {

      routeData
        .setId(+pId);

       this.offerFeeManagerConfiguration
            .setOfferOid(routeData.id)

    }

    this.configuration = CtModelConfiguration
      .create<OfferEditComponent>()
      .setRouteData(routeData)
  }


}
