import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, DeleteConfirmService,
} from "@ctsolution/ct-framework";
import {UserWorkingHoursPicturesConfiguration} from "./user-working-hours-pictures.configuration";
import {UserWorkingHoursFileController} from "../../../../../_core/controllers/user-working-hours-file.controller";
import {MAT_LEGACY_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {CTManagerFile} from "../../../../../_core/classes/ct-manager-file";
import {UserWorkingHoursFileRouteData} from "../../../../../_core/route-data/user-working-hours-file.route-data";
import { NoteDialogService } from 'projects/ct-manager/src/app/_core/services/note-services/note-dialog/note-dialog-service';
import { NoteDialogConfiguration, FileOriginEnum } from 'projects/ct-manager/src/app/_core/services/note-services/note-dialog.configuration';

@Component({
  selector: 'app-user-working-hours-pictures',
  templateUrl: './user-working-hours-pictures.component.html',
  styleUrls: ['./user-working-hours-pictures.component.scss'],
  providers: [UserWorkingHoursFileController]
})
export class UserWorkingHoursPicturesComponent implements OnInit {

  @ViewChild('fileUploader') fileUploader: any | null = null;

  pictures: Array<CTManagerFile> = []
  loading = true;
  selectedIndex: number | null = null;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: UserWorkingHoursPicturesConfiguration,
    private userWorkingHoursFileController: UserWorkingHoursFileController,
    private deleteConfirm: DeleteConfirmService,
    private modelService: CtModelService<any>,
    private noteDialogService : NoteDialogService
  ) {
  }

  ngOnInit() {

    this.getList();

  }

  onUpload(event: any) {

    const files = (event.target as HTMLInputElement).files ?? [];

    if (!files.length) return;

    const promises = [];

    for (let i = 0; i < files.length; i++) {

      const file = files[i];

      const parameter: CTManagerFile = CTManagerFile
        .create()
        .setFile(file)
        .setUserWorkingHours(this.data.userWorkingHours);

      const promise = this.userWorkingHoursFileController
        .create(parameter)
        .toPromise();

      promises.push(promise);

    }

    Promise.all(promises)
      .then(() => this.getList())
      .catch((error) => console.error(error));

  }

  getList() {

    this.loading = true;

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(UserWorkingHoursFileRouteData());

    const operators: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField("UserWorkingHours.Oid")
          .setValue(this.data.userWorkingHours?.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    this.modelService
      .getList(configuration, operators)
      .subscribe({
        next: (response) => {
          this.pictures = (<Array<CTManagerFile>>(response?.Result?.DataSource ?? [])).map(elm => CTManagerFile.create(elm));
          this.loading = false;
        },
        error: () => (error: any) => {
          console.error(error);
          this.loading = false;
        }
      });
  }

  selectPicture(selectedIndex: number | null) {

    this.selectedIndex = this.selectedIndex === selectedIndex ? null : selectedIndex;

  }

  notes(file: CTManagerFile) {

    const param : NoteDialogConfiguration = NoteDialogConfiguration.create()
    .setFileOid(file.Oid)
    .setType(FileOriginEnum.WorkingHour)

    this.noteDialogService.open(param);

  }

  deletePicture(file: CTManagerFile) {

    if (!file.Oid) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe(confirm => {

        if (confirm) {

          this.userWorkingHoursFileController
            .delete(file)
            ?.subscribe(() => this.getList());

        }

      })

  }

}
